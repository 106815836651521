import { template as template_7e63ade0d9ce4444ab3c5b0a7d3bafc2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7e63ade0d9ce4444ab3c5b0a7d3bafc2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
